const en = {
  common: {
    stayTuned: "STAY TUNED",
  },
  menu: {
    about: "About",
    community: "Community",
    contact: "Contact",
  },
  hero: {
    title1: "NextGen Social Media with AI",
    title2: "Whole World In Your Language",
    desc: "With VeryReal App, language would not be a barrier between you and the whole world; you can write and talk to anyone, making yourself seen and understood by people anywhere. All the information would be in your mother tongue; there's no cocoon here, presenting you with a real global view.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "START WITH A WISH",
    slide1Title:
      "What if everyone could talk to everyone without language barriers",
    slide1Desc:
      "If people from all over the world could engage in direct, frictionless dialogue, perhaps there would be fewer conflicts and a bit more happiness.",
    slide2Tag: "POWERED BY AI",
    slide2Title: "First ever worldwide AI-assisted IM and social media",
    slide2Desc:
      "Traverse cultural and language barriers with the senseless voice message translator.Built on our large language model, delivering ultra realistic results in realtime. You can really talk and listen to the whole world.",
    slide3Tag: "MARCHING WITH A MISSION",
    slide3Title:
      "Globalized social media should foster a sense of global human unity",
    slide3Desc:
      "No matter how far, people can communicate and talk to you like your neighbor, and they can understand your stories. Everyone is speaking their mother tongue, and the world truly feels like a community.",
  },
  slogan: {
    slogan1Title: "Borderless",
    slogan1Desc: "the whole world speaks in your mother tongue",
    slogan2Title: "Vividly",
    slogan2Desc: "create your speaking videos in multiple other languages",
    slogan3Title: "Effortless",
    slogan3Desc: "build your AI assistant helps you replying automatically",
  },
  community: {
    join: "Join",
    the: "THE",
    community: "community",
    box1Title: "Your AI Avatar",
    box1Desc:
      "Can't keep up with the rapid social media world? Respond to DMs like a whiz & keep your inbox clean 24/7.",
    box2Title: "Connect the world",
    box2Desc:
      "You can now make friends with someone halfway round the world regardless of language. All text messages and content are translated in realtime.",
    box3Title: "Expand your cultural horizons",
    box3Desc:
      "The whole world is presented in your mother tongue, making it easy to access information, knowledge, and entertainment content from around the globe. Language no longer forms your cocoon of information.",
    box4Title: "View feed in your native tongue",
    box4Desc:
      "Our AI powered assistant conveniently translates your feed all while you browse the app.",
  },
  section4: {
    forBusiness: "for business",
    title: "Do you use social media to market your business?",
    desc: "Scale new heights and reach customers in different geographies. Don’t let language affect your customer lifecycle. Increase CTR of your ads and reach a wider market with our AI tools.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "Media Kit",
    career: "Career",
    disclaimer: "Disclaimer",
    privacyPolicy: "Privacy Policy",
    getConnected: "Get Connected",
    blog: "Blog",
    newsletter: "Newsletter",
  },
  features: {
    TRAVEL: "TRAVEL",
    DATING: "DATING",
    MARKETING: "MARKETING",
    ENTERTAINMENT: "ENTERTAINMENT",
    AID: "AID",
    INTERCUTURAL: "INTERCUTURAL",
    list1: {
      desc1:
        "Embark on a Global Adventure - Discover, Explore, and Experience Every Culture and Corner of the World.",
      desc2: "TRAVEL GLOBAL",
    },
    list2: {
      desc1:
        "Find Your Heart's Match Across the Globe - Connect, Love, and Share Across Borders in a World Without Limits.",
      desc2: "GLOBAL DATING",
    },
    list3: {
      desc1:
        "Elevate Your Brand on a Global Scale - Engage, Influence, and Inspire Audiences Around the World.",
      desc2: "GLOBAL MARKETING",
    },
    list4: {
      desc1:
        "Dive into Universal Entertainment - Experience a World of Fun, Laughter, and Joy without Boundaries.",
      desc2: "GLOBAL ENTERTAIN",
    },
    list5: {
      desc1:
        "Join Hands in Global Solidarity - Empower, Support, and Transform Lives Together for a Better World.",
      desc2: "GLOBAL AID",
    },
    list6: {
      desc1:
        "Foster Understanding and Connection - Engage in Rich, Diverse Dialogues that Unite Cultures and Communities Globally.",
      desc2: "GLOBAL CONNECTION",
    },
    title: "Build to go global.",
  },
};

export default en;
