import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

const MobileHeader = ({ setLang, language, menus, lang }) => {
  const [openLan, setOpenLan] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <div
          onClick={() => {
            setOpenMenu(false);
            setOpenLan(!openLan);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginRight: "0.75rem",
          }}
        >
          <div style={{ color: "white", marginRight: "0.25rem" }}>
            {" "}
            {lang?.toUpperCase() ?? "EN"}
          </div>
          <IoIosArrowDropdown color="white" size={"1.25rem"} />
        </div>
        <div
          onClick={() => {
            setOpenLan(false);
            setOpenMenu(!openMenu);
          }}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <GiHamburgerMenu color="white" size={"1.5rem"} />
        </div>

        <AnimatePresence>
          {openMenu && (
            <motion.div
              className="lan-btn-content nav-btn-content"
              style={{
                originY: 0,
                originX: 0.8,
                right: 0,
              }}
              variants={{
                hover: {
                  scale: 1,
                  transition: {
                    duration: 0.2,
                  },
                },
                initial: {
                  scale: 0,
                  transition: {
                    duration: 0.2,
                  },
                },
              }}
              initial="initial"
              animate="hover"
              exit="initial"
            >
              <ul className="lan-list">
                {menus?.map((menu, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        window.location.href = `#${menu.anchor}`;
                        setOpenMenu(!openMenu);
                      }}
                    >
                      {menu.title}
                    </li>
                  );
                })}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {openLan && (
            <motion.div
              className="lan-btn-content nav-btn-content"
              style={{
                originY: 0,
                originX: 0.8,
                right: 26,
              }}
              variants={{
                hover: {
                  scale: 1,
                  transition: {
                    duration: 0.2,
                  },
                },
                initial: {
                  scale: 0,
                  transition: {
                    duration: 0.2,
                  },
                },
              }}
              initial="initial"
              animate="hover"
              exit="initial"
            >
              <ul className="lan-list">
                {language?.map((item) => {
                  return (
                    <li
                      key={item.code}
                      onClick={() => {
                        setLang(item.code);
                        localStorage.setItem("lang", item.code);
                        setOpenLan(!openLan);
                      }}
                      style={{
                        color: lang === item.code ? "#F38ED1" : "#FFFFFF",
                      }}
                    >
                      {item.lan}
                    </li>
                  );
                })}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const Header = ({ setLang, lang, langObj }) => {
  const [openLan, setOpenLan] = useState(false);

  const language = [
    { code: "en", lan: "English" },
    { code: "es", lan: "Español" },
    { code: "pt", lan: "Português" },
    { code: "zh_cn", lan: "简体中文" },
    { code: "zh_tw", lan: "繁體中文" },
    { code: "ja", lan: "日本語" },
    { code: "ko", lan: "한국어" },
    { code: "vi", lan: "Tiếng Việt" },
    { code: "hi", lan: "हिंदी" },
    { code: "tr", lan: "Türkçe" },
  ];

  const convertToLongLang = (name) => {
    switch (name) {
      case "en":
        return "English";
      case "es":
        return "Español";
      case "pt":
        return "Português";
      case "zh_cn":
        return "简体中文";
      case "zh_tw":
        return "繁體中文";
      case "ja":
        return "日本語";
      case "ko":
        return "한국어";
      case "vi":
        return "Tiếng Việt";
      case "hi":
        return "हिंदी";
      case "tr":
        return "Türkçe";
      default:
        return "English";
    }
  };

  const menus = [
    { title: langObj?.menu?.about, anchor: "about" },
    { title: langObj?.menu?.community, anchor: "community" },
    { title: langObj?.menu?.contact, anchor: "contact" },
  ];

  return (
    <div className="header-wrapper">
      <div>
        <img src="/header-logo.png" alt="header logo" className="header-logo" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
        className="desktop-menu"
      >
        {menus.map((menu, index) => {
          return (
            <div key={index}>
              <a
                style={{
                  color: "white",
                  marginRight: "3rem",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                href={`#${menu.anchor}`}
              >
                {menu.title}
              </a>
            </div>
          );
        })}

        <div
          style={{
            position: "relative",
          }}
        >
          <div
            onClick={() => {
              setOpenLan(!openLan);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div style={{ color: "white", marginRight: "0.5rem" }}>
              {convertToLongLang(lang) ?? "EN"}
            </div>
            <IoIosArrowDropdown color="white" size={"1.25rem"} />
          </div>

          <AnimatePresence>
            {openLan && (
              <motion.div
                className="lan-btn-content nav-btn-content"
                style={{
                  originY: 0,
                  originX: 0.8,
                }}
                variants={{
                  hover: {
                    scale: 1,
                    transition: {
                      duration: 0.2,
                    },
                  },
                  initial: {
                    scale: 0,
                    transition: {
                      duration: 0.2,
                    },
                  },
                }}
                initial="initial"
                animate="hover"
                exit="initial"
              >
                <ul className="lan-list">
                  {language?.map((item) => {
                    return (
                      <li
                        key={item.code}
                        onClick={() => {
                          setLang(item.code);
                          localStorage.setItem("lang", item.code);
                          setOpenLan(!openLan);
                        }}
                        style={{
                          color: lang === item.code ? "#F38ED1" : "#FFFFFF",
                        }}
                      >
                        {item.lan}
                      </li>
                    );
                  })}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="mobile-menu">
        <MobileHeader
          setLang={setLang}
          language={language}
          menus={menus}
          lang={lang}
        />
      </div>
    </div>
  );
};

export default Header;
