import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import Header from "./Header";

const Layout = ({ children, setLang, lang, langObj }) => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <Header setLang={setLang} lang={lang} langObj={langObj} />

      <div>{children}</div>
    </div>
  );
};

export default Layout;
