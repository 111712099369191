const vi = {
  common: {
    stayTuned: "Duy trì theo dõi",
  },
  menu: {
    about: "Về",
    community: "Cộng đồng",
    contact: "Liên hệ",
  },
  hero: {
    title1: "Truyền thông xã hội NextGen với AI",
    title2: "Cả thế giới bằng ngôn ngữ của bạn",
    desc: "Với ứng dụng của chúng tôi, ngôn ngữ sẽ không phải là một rào cản giữa bạn và toàn thế giới; Bạn có thể viết và nói chuyện với bất cứ ai, làm cho mình nhìn thấy và hiểu bởi mọi người ở bất cứ đâu. Tất cả các thông tin sẽ bằng tiếng mẹ của bạn; Không có cái kén ở đây, đưa cho bạn một cái nhìn toàn cầu thực sự.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "Bắt đầu với một điều ước",
    slide1Title:
      "Điều gì sẽ xảy ra nếu mọi người có thể nói chuyện với mọi người mà không có rào cản ngôn ngữ",
    slide1Desc:
      "Nếu mọi người từ khắp nơi trên thế giới có thể tham gia vào cuộc đối thoại trực tiếp, không ma sát, có lẽ sẽ có ít xung đột hơn và hạnh phúc hơn một chút.",
    slide2Tag: "Được cung cấp bởi AI",
    slide2Title: "IM và mạng xã hội hỗ trợ toàn cầu.",
    slide2Desc:
      "Transverse văn hóa và các rào cản ngôn ngữ với người dịch tin nhắn vô nghĩa. BURILT TRÊN MÔ HÌNH NGÔN NGỮ LỚN CỦA CHÚNG TÔI, mang lại kết quả cực kỳ thực tế trong thời gian thực. Bạn thực sự có thể nói chuyện và lắng nghe cả thế giới.",
    slide3Tag: "Diễu hành với một nhiệm vụ",
    slide3Title: "Mạng xã hội thúc đẩy ý thức toàn cầu.",
    slide3Desc:
      "Cho dù mọi người có thể giao tiếp và nói chuyện với bạn như hàng xóm của bạn và họ có thể hiểu câu chuyện của bạn. Mọi người đang nói tiếng mẹ đẻ của họ, và thế giới thực sự cảm thấy như một cộng đồng.",
  },
  slogan: {
    slogan1Title: "Không biên giới",
    slogan1Desc: "Cả thế giới nói bằng tiếng mẹ của bạn",
    slogan2Title: "Sinh động.",
    slogan2Desc: "Tạo video nói của bạn bằng nhiều ngôn ngữ khác",
    slogan3Title: "Dễ dàng",
    slogan3Desc: "Xây dựng trợ lý AI của bạn giúp bạn trả lời tự động",
  },
  community: {
    join: "Tham gia",
    the: "CÁC",
    community: "cộng đồng",
    box1Title: "AI AVATAR của bạn",
    box1Desc:
      "Không thể theo kịp thế giới truyền thông xã hội nhanh chóng? Trả lời DMS như một whiz & giữ cho hộp thư đến của bạn sạch sẽ 24/7.",
    box2Title: "Kết nối thế giới",
    box2Desc:
      "Bây giờ bạn có thể kết bạn với ai đó nửa vòng quanh thế giới bất kể ngôn ngữ. Tất cả các tin nhắn văn bản và nội dung được dịch trong thời gian thực.",
    box3Title: "Mở rộng tầm nhìn văn hóa của bạn",
    box3Desc:
      "Cả thế giới được trình bày bằng tiếng mẹ đẻ của bạn, giúp dễ dàng truy cập thông tin, kiến ​​thức và nội dung giải trí từ khắp nơi trên thế giới. Ngôn ngữ không còn hình thành cái kén thông tin của bạn.",
    box4Title: "Xem nguồn cấp dữ liệu bằng tiếng mẹ đẻ của bạn",
    box4Desc: "Trợ lý AI có thể dịch mọi nguồn dữ liệu khi bạn duyệt ứng dụng.",
  },
  section4: {
    forBusiness: "cho doanh nghiệp",
    title: "Bạn có sử dụng mạng xã hội để quảng bá doanh nghiệp không?",
    desc: "Nâng cao chiều cao và kết nối với khách hàng ở nhiều khu vực địa lý khác nhau thông qua ảnh hưởng của ngôn ngữ. Tăng CTR quảng cáo và mở rộng thị trường với công cụ AI của chúng tôi.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "Bộ truyền thông",
    career: "Sự nghiệp",
    disclaimer: "Tuyên bố miễn trừ trách nhiệm",
    privacyPolicy: "Chính sách bảo mật",
    getConnected: "Được kết nối",
    blog: "Blog",
    newsletter: "Bản tin",
  },
  features: {
    TRAVEL: "DU LỊCH",
    DATING: "HẸN HÒ",
    MARKETING: "TIẾP THỊ",
    ENTERTAINMENT: "SỰ GIẢI TRÍ",
    AID: "Hỗ trợ",
    INTERCUTURAL: "Liên ngành",
    list1: {
      desc1:
        "Bắt tay vào một cuộc phiêu lưu toàn cầu - khám phá, khám phá và trải nghiệm mọi nền văn hóa và góc của thế giới.",
      desc2: "Du lịch toàn cầu",
    },
    list2: {
      desc1:
        "Tìm trận đấu của trái tim bạn trên toàn cầu - kết nối, tình yêu và chia sẻ qua biên giới trong một thế giới không giới hạn.",
      desc2: "Hẹn hò toàn cầu",
    },
    list3: {
      desc1:
        "Nâng cao thương hiệu của bạn trên quy mô toàn cầu - tham gia, ảnh hưởng và truyền cảm hứng cho khán giả trên khắp thế giới.",
      desc2: "Tiếp thị toàn cầu",
    },
    list4: {
      desc1:
        "Đi sâu vào giải trí toàn cầu - Trải nghiệm một thế giới vui vẻ, tiếng cười và niềm vui mà không có ranh giới.",
      desc2: "Giải trí toàn cầu",
    },
    list5: {
      desc1:
        "Tham gia vào sự đoàn kết toàn cầu - trao quyền, hỗ trợ và biến đổi cuộc sống cùng nhau cho một thế giới tốt đẹp hơn.",
      desc2: "Viện trợ toàn cầu",
    },
    list6: {
      desc1:
        "Thúc đẩy sự hiểu biết và kết nối - tham gia vào các cuộc đối thoại phong phú, đa dạng, đoàn kết các nền văn hóa và cộng đồng trên toàn cầu.",
      desc2: "Kết nối toàn cầu",
    },
    title: "Xây dựng để đi toàn cầu.",
  },
};

export default vi;
