import React from "react";
import { motion } from "framer-motion";

const Slogans = ({ langObj }) => {
  const slogans = [
    {
      title: langObj?.slogan?.slogan1Title,
      desc: langObj?.slogan?.slogan1Desc,
      left: "#EF87FE",
      right: "#5401BE",
    },
    {
      title: langObj?.slogan?.slogan2Title,
      desc: langObj?.slogan?.slogan2Desc,
      left: "#5EF8EE",
      right: "#0E50FA",
    },
    {
      title: langObj?.slogan?.slogan3Title,
      desc: langObj?.slogan?.slogan3Desc,
      left: "#78FD96",
      right: "#09768E",
    },
  ];

  return (
    <div
      style={{
        minHeight: "100vh",
        maxWidth: "960px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "0 2rem",
        margin: "auto",
      }}
    >
      <div style={{}}>
        {slogans.map((item, index) => {
          return (
            <motion.div
              key={index}
              style={{ marginBottom: "3rem" }}
              initial={{ opacity: 0, y: 200 }}
              whileInView={{ opacity: 1, y: 0 }}
              duration={{ delay: 1 }}
            >
              <div
                style={{
                  backgroundImage: `linear-gradient(to right, ${item.left}, ${item.right})`,
                }}
                className="slogan-title"
              >
                {item.title}
              </div>
              <div style={{ color: "white" }}>{item.desc}</div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Slogans;
