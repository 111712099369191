const zh_cn = {
  common: {
    stayTuned: "敬请期待",
  },
  menu: {
    about: "关于",
    community: "社区",
    contact: "联系我们",
  },
  hero: {
    title1: "新一代AI助力社交媒体",
    title2: "让全世界都说你的语言",
    desc: "在VeryReal App，您与全世界之间不再有语言障碍。您可以与任何人写作和交谈，被世界各地的人们看到和理解。所有的信息以您的母语呈现；这里没有信息茧房，向您展现真正的全球视野。",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "从一个愿望开始",
    slide1Title: "如果每个人都可以在没有语言障碍的情况下与所有人交谈",
    slide1Desc:
      "如果来自世界各地的人们可以进行直接对话，那么也许会有更少的冲突和更多的幸福。",
    slide2Tag: "由人工智能驱动",
    slide2Title: "首个全球化的AI辅助即时通讯软件和社交媒体",
    slide2Desc:
      "通过沉浸式地语音消息翻译，VeryReal帮助你穿越文化和语言障碍。它在我们的大语言模型上构建，提供贴切的全面时时翻译，让您可以真正谈论和倾听整个世界。",
    slide3Tag: "带着使命再前进",
    slide3Title: "用全球化的社交媒体滋养一种全球公民的文化",
    slide3Desc:
      "无论多远，人们都可以像邻居一样交谈，他们可以理解与同情远在他乡的故事。每个人都在说自己的母语，真正地感觉到全世界就像一个社区。",
  },
  slogan: {
    slogan1Title: "没有语言边界",
    slogan1Desc: "全世界用你的母语说话",
    slogan2Title: "传递无边活力",
    slogan2Desc: "用其他多种语言创建您的视频表达",
    slogan3Title: "自建AI助理",
    slogan3Desc: "建立您的AI助手可帮助您自动回复",
  },
  community: {
    join: "加入",
    the: "这个",
    community: "全球社区",
    box1Title: "打造你的AI分身",
    box1Desc:
      "无法跟上快速的社交媒体世界？通过AI分身自动响应DMS，时刻保持收件箱24/7完美智能回复。",
    box2Title: "连接世界各地",
    box2Desc:
      "现在，无论任何语言，您都可以与世界另一端的人交朋友。所有对话与语音均以实时翻译。",
    box3Title: "扩展文化视野",
    box3Desc:
      "全世界信息都以您的母语呈现，轻松访问来自全球的信息、知识和娱乐。语言将不再构成信息茧房。",
    box4Title: "用母语时时反馈",
    box4Desc:
      "当您浏览我们的应用程序时，我们的AI助理也会随时将您的反馈，随时传递为他人可读的语言。",
  },
  section4: {
    forBusiness: "还有广阔的商业用途",
    title: "您是否使用社交媒体来推销您的业务？",
    desc: "通过我们您可以将业务扩展到新高度，在世界各地轻松吸引客户。语言不再会影响您的获客与维护。显著增加广告的CTR，我们的AI工具助力您到达更广泛的市场。",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "媒体工具包",
    career: "职业发展",
    disclaimer: "免责声明",
    privacyPolicy: "隐私政策",
    getConnected: "保持联络",
    blog: "博客",
    newsletter: "邮件订阅",
  },
  features: {
    TRAVEL: "旅行",
    DATING: "约会",
    MARKETING: "营销",
    ENTERTAINMENT: "娱乐",
    AID: "援助",
    INTERCUTURAL: "跨文化",
    list1: {
      desc1: "踏上全球冒险 - 发现、探索和体验世界上每个文化和角落。",
      desc2: "旅行全球",
    },
    list2: {
      desc1: "在全球范围内找到您的心跳匹配 - 在世界上连接爱和感情。",
      desc2: "全球约会",
    },
    list3: {
      desc1: "在全球范围内提升您的品牌 - 参与、影响和吸引世界各地的顾客。",
      desc2: "全球营销",
    },
    list4: {
      desc1: "更多丰富环球娱乐 - 理解带来乐趣，一个欢乐的全世界向你打开。",
      desc2: "全球娱乐",
    },
    list5: {
      desc1:
        "更多理解更多团结 - 听见、看见并理解远方的苦难，共同援助更美好的世界。",
      desc2: "全球援助",
    },
    list6: {
      desc1: "促进跨文化理解和联系 - 帮助多文化的家庭、社区开展轻松的对话。",
      desc2: "全球连接",
    },
    title: "为走向全球而生。",
  },
};

export default zh_cn;
