import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const Hero = ({ langObj }) => {
  const [openAndroid, setOpenAndroid] = useState(false);

  const list = [
    {
      label: "Google Play",
      url: "https://play.google.com/store/apps/details?id=com.veryrealai.flutter",
    },
    {
      label: "APK",
      url: "https://veryreal-app.s3.ap-northeast-1.amazonaws.com/VeryReal.apk",
    },
  ];

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
      id="about"
    >
      <div style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }}>
        <img
          src="/bg/gradient-1.png"
          alt="gradient-1"
          className="hero-gradient-bg"
        />
      </div>
      <div style={{ position: "absolute", right: 0, bottom: -10, zIndex: 2 }}>
        <img
          src="/bg/gradient-2.png"
          alt="gradient-2"
          className="hero-gradient-bg"
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            fontFamily: "Barlow Condensed",
            fontSize: "4.75rem",
            fontStyle: "italic",
            color: "white",
            fontWeight: 600,
            marginBottom: "1rem",
            padding: "0 2rem",
            marginTop: "0.5rem",
          }}
          className="hero-title"
        >
          <div>{langObj?.hero?.title1}</div>
          <div>{langObj?.hero?.title2}</div>
        </div>
        <div
          style={{
            color: "white",
            maxWidth: "620px",
            margin: "0 auto 3rem auto",
            padding: "0 2rem",
          }}
        >
          {langObj?.hero?.desc}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            position: "relative",
            zIndex: "3",
          }}
        >
          {/* android */}
          {/* <a
            href="https://veryreal-app.s3.ap-northeast-1.amazonaws.com/VeryReal.apk"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          > */}

          {/* </a> */}
          <div style={{ position: "relative" }}>
            <div
              className="button-gradient"
              style={{
                width: "160px",
                marginRight: "1rem",
                marginTop: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenAndroid(!openAndroid);
              }}
            >
              {langObj?.hero?.android}
            </div>
            <AnimatePresence>
              {openAndroid && (
                <motion.div
                  className="lan-btn-content nav-btn-content"
                  style={{
                    originY: 0,
                    originX: 0.8,
                    right: "calc(50% - 52px)",
                    top: "65px",
                  }}
                  variants={{
                    hover: {
                      scale: 1,
                      transition: {
                        duration: 0.2,
                      },
                    },
                    initial: {
                      scale: 0,
                      transition: {
                        duration: 0.2,
                      },
                    },
                  }}
                  initial="initial"
                  animate="hover"
                  exit="initial"
                >
                  <ul className="lan-list">
                    {list?.map((item, index) => {
                      return (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          <li
                            key={index}
                            onClick={() => {
                              setOpenAndroid(!openAndroid);
                            }}
                          >
                            {item.label}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <a
            href="https://apps.apple.com/app/veryrealai/id6473885232"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div
              className="button-line"
              style={{ width: "160px", marginTop: "0.5rem" }}
            >
              {langObj?.hero?.ios}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
