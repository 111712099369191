const pt = {
  common: {
    stayTuned: "MANTENHA-SE SINTONIZADO",
  },
  menu: {
    about: "Sobre",
    community: "Comunidade",
    contact: "Contato",
  },
  hero: {
    title1: "Mídia social nextgen com ai",
    title2: "Mundo inteiro em sua língua",
    desc: "Com nosso aplicativo, a linguagem não seria uma barreira entre você e o mundo inteiro; Você pode escrever e conversar com qualquer pessoa, fazendo -se vista e compreendida pelas pessoas em qualquer lugar. Todas as informações estariam na sua língua materna; Não há nenhum casulo aqui, apresentando a você uma visão global real.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "Comece com um desejo",
    slide1Title:
      "E se todos pudessem falar com todos sem barreiras linguísticas",
    slide1Desc:
      "Se pessoas de todo o mundo pudessem se envolver em diálogo direto e sem atrito, talvez haja menos conflitos e um pouco mais de felicidade.",
    slide2Tag: "Alimentado por AI",
    slide2Title: "Primeiro IM e mídia social assistidos pelo Worldwide Ai",
    slide2Desc:
      "Traverse Barreiras culturais e de linguagem com o tradutor de mensagem de voz sem sentido. Você pode realmente falar e ouvir o mundo inteiro.",
    slide3Tag: "Marchando com uma missão",
    slide3Title:
      "A mídia social globalizada deve promover um senso de unidade humana global",
    slide3Desc:
      "Não importa o quão longe, as pessoas possam se comunicar e conversar com você como seu vizinho, e elas podem entender suas histórias. Todo mundo está falando a língua materna, e o mundo realmente parece uma comunidade.",
  },
  slogan: {
    slogan1Title: "Sem fronteiras",
    slogan1Desc: "O mundo inteiro fala em sua língua materna",
    slogan2Title: "Vividamente",
    slogan2Desc: "Crie seus vídeos de falar em vários outros idiomas",
    slogan3Title: "Sem esforço",
    slogan3Desc:
      "Construa seu assistente de IA ajuda você a responder automaticamente",
  },
  community: {
    join: "Juntar",
    the: "O",
    community: "comunidade",
    box1Title: "Seu avatar da AI",
    box1Desc:
      "Não consegue acompanhar o rápido mundo das mídias sociais? Responda a DMs como um gênio e mantenha sua caixa de entrada limpa 24/7.",
    box2Title: "Conectar o mundo",
    box2Desc:
      "Agora você pode fazer amizade com alguém no meio do mundo, independentemente da linguagem. Todas as mensagens de texto e conteúdo são traduzidos em tempo real.",
    box3Title: "Expanda seus horizontes culturais",
    box3Desc:
      "O mundo inteiro é apresentado em sua língua materna, facilitando o acesso a informações, conhecimentos e conteúdo de entretenimento de todo o mundo. A linguagem não forma mais seu casulo de informação.",
    box4Title: "Ver Feed em sua língua nativa",
    box4Desc:
      "Nosso assistente alimentado por IA traduz convenientemente seu feed enquanto você navega no aplicativo.",
  },
  section4: {
    forBusiness: "para negócios",
    title: "Você usa a mídia social para comercializar o seu negócio?",
    desc: "Escala novos patamares e alcançar clientes em diferentes geografias. Não deixe a linguagem afetar o ciclo de vida do seu cliente. Aumente a CTR de seus anúncios e alcance um mercado mais amplo com nossas ferramentas de IA.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "Kit de mídia",
    career: "Carreira",
    disclaimer: "Isenção",
    privacyPolicy: "política de Privacidade",
    getConnected: "Conecte -se",
    blog: "Blog",
    newsletter: "Boletim de Notícias",
  },
  features: {
    TRAVEL: "VIAGEM",
    DATING: "NAMORANDO",
    MARKETING: "MARKETING",
    ENTERTAINMENT: "ENTRETENIMENTO",
    AID: "AJUDA",
    INTERCUTURAL: "Intercutural",
    list1: {
      desc1:
        "Embarque em uma aventura global - Descubra, explore e experimente todas as culturas e cantos do mundo.",
      desc2: "Viagens globais",
    },
    list2: {
      desc1:
        "Encontre a partida do seu coração em todo o mundo - conecte, amor e compartilhe através de fronteiras em um mundo sem limites.",
      desc2: "Namoro global",
    },
    list3: {
      desc1:
        "Eleve sua marca em escala global - envolver, influenciar e inspirar o público em todo o mundo.",
      desc2: "Marketing Global",
    },
    list4: {
      desc1:
        "Mergulhe no entretenimento global - experimente um mundo de diversão, risos e alegria sem limites.",
      desc2: "Entretenimento Global",
    },
    list5: {
      desc1:
        "Junte as mãos na solidariedade global - capacitar, apoiar e transformar vidas para um mundo melhor.",
      desc2: "Ajuda Global",
    },
    list6: {
      desc1:
        "Fomentar o entendimento e a conexão - envolva -se em diálogos ricos e diversos que unem culturas e comunidades globalmente.",
      desc2: "Conexão Global",
    },
    title: "Construir para se tornar global.",
  },
};

export default pt;
