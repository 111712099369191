import React from "react";
import Modal from "../modal";
import { RxCross2 } from "react-icons/rx";

const StayTunedModal = ({ langObj, open, setStayTunedModalOpen }) => {
  return (
    <Modal open={open}>
      <div className="modal-container" style={{ position: "relative" }}>
        <RxCross2
          size={22}
          className="modal-close-btn"
          onClick={() => {
            setStayTunedModalOpen(false);
          }}
        />
        <div
          style={{
            padding: "3rem 5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p>{langObj?.common?.stayTuned}</p>
          {/* <div className="cta-group" style={{ paddingBottom: "0.5rem" }}>
            <div className="btn-group">
              <p
                className="transparent-bordered-btn"
                onClick={() => {
                  setStayTunedModalOpen(false);
                }}
              >
                {langObj?.common?.ok}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default StayTunedModal;
