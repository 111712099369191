import React from "react";

const Contacts = ({ langObj, setStayTunedModalOpen }) => {
  const openModal = () => setStayTunedModalOpen(true);
  const veryRealAIColumnList = [
    { label: langObj?.contacts?.mediaKit, onClickFunction: openModal },
    { label: langObj?.contacts?.career, onClickFunction: openModal },
    { label: langObj?.contacts?.disclaimer, onClickFunction: openModal },
    { label: langObj?.contacts?.privacyPolicy, onClickFunction: openModal },
  ];
  const getConnectedColumnList = [
    { label: langObj?.contacts?.blog, onClickFunction: openModal },
    { label: langObj?.contacts?.newsletter, onClickFunction: openModal },
  ];
  return (
    <div className="contacts-container" id="contact">
      <img
        src="/bg/contacts-bg.png"
        alt="contacts bg"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          right: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <div className="contacts-socials">
        <img
          className="contacts-logo"
          src="/img/contacts-logo.png"
          alt="logo"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="contacts-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61552991131549"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <img
                src="/socials/facebook.svg"
                alt="logo"
                className="contacts-icon"
              />
            </a>

            <a
              href="https://twitter.com/VeryRealAI"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <img
                src="/socials/twitter.svg"
                alt="logo"
                className="contacts-icon"
              />
            </a>

            <a
              href="https://www.instagram.com/veryrealai/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <img
                src="/socials/instagram.svg"
                alt="logo"
                className="contacts-icon"
              />
            </a>

            <a
              href="https://www.tiktok.com/@veryrealai"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              <img
                src="/socials/tiktok.svg"
                alt="logo"
                className="contacts-icon"
              />
            </a>
          </div>
          <div className="contacts-copyright-email">
            © {langObj?.contacts?.copyright}
          </div>
          <div className="contacts-copyright-email">
            {langObj?.contacts?.email}
          </div>
        </div>
      </div>
      <div className="contacts-links">
        <div className="contacts-links-column">
          <div className="contacts-links-column-header">
            {langObj?.contacts?.veryRealAI}
          </div>
          {veryRealAIColumnList.map((item, index) => {
            return (
              <div className="contacts-link" onClick={item.onClickFunction}>
                {item.label}
              </div>
            );
          })}
        </div>
        <div className="contacts-links-column">
          <div className="contacts-links-column-header">
            {langObj?.contacts?.getConnected}
          </div>
          {getConnectedColumnList.map((item, index) => {
            return (
              <div className="contacts-link" onClick={item.onClickFunction}>
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Contacts;
