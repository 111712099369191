import React, { useState } from "react";
import { Carousel as Crsl } from "react-responsive-carousel";

const Features = ({ langObj }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleChangeItem = (number) => {
    setSelectedItem(number);
  };

  const lists = [
    {
      title: langObj?.features?.TRAVEL,
      color: "#E67FFA",
      desc1: langObj?.features?.list1?.desc1,
      desc2: langObj?.features?.list1?.desc2,
    },
    {
      title: langObj?.features?.DATING,
      color: "#9539FF",
      desc1: langObj?.features?.list2?.desc1,
      desc2: langObj?.features?.list2?.desc2,
    },
    {
      title: langObj?.features?.MARKETING,
      color: "#78FD96",
      desc1: langObj?.features?.list3?.desc1,
      desc2: langObj?.features?.list3?.desc2,
    },
    {
      title: langObj?.features?.ENTERTAINMENT,
      color: "#49B2FF",
      desc1: langObj?.features?.list4?.desc1,
      desc2: langObj?.features?.list4?.desc2,
    },
    {
      title: langObj?.features?.AID,
      color: "#FF6666",
      desc1: langObj?.features?.list5?.desc1,
      desc2: langObj?.features?.list5?.desc2,
    },
    {
      title: langObj?.features?.INTERCUTURAL,
      color: "#3CFFF3",
      desc1: langObj?.features?.list6?.desc1,
      desc2: langObj?.features?.list6?.desc2,
    },
  ];

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "2.5rem",
        borderBottom: "1px solid white",
      }}
    >
      <img
        src="/features/features-bg.png"
        alt="features-bg"
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          pointerEvents: "none",
        }}
      />
      <img
        src="/features/star.png"
        alt="star"
        style={{
          position: "absolute",
          bottom: -40,
          right: 0,
          pointerEvents: "none",
          width: "80px",
        }}
      />
      <div
        style={{
          margin: "0 0 8rem 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <div className="features-title">{langObj?.features?.title}</div>
          <img
            src="/features/title-stroke.png"
            alt="title-stroke"
            style={{ width: "160px" }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          maxWidth: "1150px",
          margin: "0 auto",
          padding: "0 2rem",
          // flexWrap: "wrap",
          position: "relative",
          overflow: "scroll",
        }}
      >
        {lists.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                color: selectedItem === index ? item.color : "#D9D9D9",
                border: `1px solid ${
                  selectedItem === index ? item.color : "#D9D9D9"
                }`,
                marginBottom: "2rem",
              }}
              className="features-btn"
              onClick={() => handleChangeItem(index)}
            >
              {item.title}
            </div>
          );
        })}
      </div>

      <Crsl
        autoPlay
        interval={5000}
        showArrows={false}
        infiniteLoop={true}
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={selectedItem}
        onChange={(e) => {
          handleChangeItem(e);
        }}
      >
        {lists.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
              className="features-carousel-mobile"
            >
              <div>
                <img
                  src={`/features/${index + 1}-1.png`}
                  alt={`${index + 1}-1`}
                  className="features-carousel-mobile-img"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                  margin: "0 1rem",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#131212",
                    borderRadius: "1rem",
                    flex: 1,
                    width: "300px",
                    marginBottom: "1rem",
                    padding: "1rem",
                    textAlign: "left",
                    color: "white",
                    fontStyle: "italic",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    lineHeight: "1.5rem",
                  }}
                  className="features-carousel-mobile-img"
                >
                  <div>{item?.desc1}</div>
                </div>
                <div
                  style={{
                    backgroundColor: item.color,
                    borderRadius: "1rem",
                    flex: 1,
                    width: "300px",
                    padding: "1rem",
                    textAlign: "left",
                    color: "black",
                    fontStyle: "italic",
                    fontFamily: "Anonymous Pro",
                    fontWeight: 700,
                    fontSize: "2.5rem",
                  }}
                  className="features-carousel-mobile-img"
                >
                  <div>{item?.desc2}</div>
                </div>
              </div>
              <div>
                <img
                  src={`/features/${index + 1}-4.png`}
                  alt={`${index + 1}-4`}
                  className="features-carousel-mobile-img"
                />
              </div>
            </div>
          );
        })}
      </Crsl>
    </div>
  );
};

export default Features;
