const Modal = ({ open, setOpen, user, setUser, children }) => {
  return (
    <div style={{ zIndex: "999" }}>
      {open ? (
        <div className="modal" style={{ zIndex: "999" }}>
          <div className="modal-wrapper">
            <div
              className="modal-bg-loading"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="modal-bg">{children}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
