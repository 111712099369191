const tr = {
  common: {
    stayTuned: "Beklemede kal",
  },
  menu: {
    about: "Hakkında",
    community: "Toplum",
    contact: "Temas etmek",
  },
  hero: {
    title1: "AI ile NextGen Sosyal Medya",
    title2: "Dilinizdeki tüm dünya",
    desc: "Uygulamamızla, dil sizin ve tüm dünya arasında bir engel olmazdı; Herkesle yazabilir ve konuşabilirsiniz, kendinizi herhangi bir yerde insanlar tarafından görülebilir ve anlamalıdır. Tüm bilgiler anadilinizde olacaktır; Burada size gerçek bir küresel görüş sunarak koza yok.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "Bir dilekle başlayın",
    slide1Title: "Ya herkes dil engelleri olmadan herkesle konuşabilseydi",
    slide1Desc:
      "Dünyanın her yerinden insanlar doğrudan, sürtünmesiz diyaloga girebilseydi, belki de daha az çatışma ve biraz daha fazla mutluluk olurdu.",
    slide2Tag: "AI destekli",
    slide2Title: "Dünya çapında ilk AI destekli IM ve Sosyal Medya",
    slide2Desc:
      "Anlamsız sesli mesaj çevirmeni ile kültürel ve dil engellerini geçin. Büyük dil modelimizde, gerçek zamanlı olarak ultra gerçekçi sonuçlar sunarak. Gerçekten tüm dünyayı konuşabilir ve dinleyebilirsiniz.",
    slide3Tag: "Bir görevle yürümek",
    slide3Title:
      "Küreselleşmiş Sosyal Medya, küresel insan birliği duygusunu teşvik etmelidir",
    slide3Desc:
      "Ne kadar uzak olursa olsun, insanlar sizinle komşunuz gibi iletişim kurabilir ve konuşabilirler ve hikayelerinizi anlayabilirler. Herkes ana dillerini konuşuyor ve dünya gerçekten bir topluluk gibi geliyor.",
  },
  slogan: {
    slogan1Title: "Sınırsız",
    slogan1Desc: "Bütün dünya anadilinizde konuşuyor",
    slogan2Title: "Canlı",
    slogan2Desc: "Konuşma videolarınızı diğer birçok dilde oluşturun",
    slogan3Title: "Zahmetsiz",
    slogan3Desc:
      "AI Asistanınızı Oluşturun Otomatik olarak yanıt vermenize yardımcı olur",
  },
  community: {
    join: "Katılmak",
    the: ".",
    community: "toplum",
    box1Title: "AI Avatarınız",
    box1Desc:
      "Hızlı sosyal medya dünyasına ayak uyduramıyor musunuz? DMS'ye bir vızıltı gibi yanıt verin ve gelen kutunuzu 7/24 temiz tutun.",
    box2Title: "Dünyayı Bağla",
    box2Desc:
      "Artık dilden bağımsız olarak dünyanın yarısında biriyle arkadaş olabilirsiniz. Tüm kısa mesajlar ve içerik gerçek zamanlı olarak çevrilir.",
    box3Title: "Kültürel ufuklarınızı genişletin",
    box3Desc:
      "Tüm dünya anadilinizde sunulur ve dünyanın dört bir yanından bilgi, bilgi ve eğlence içeriğine erişmeyi kolaylaştırır. Dil artık bilgi kozunuzu oluşturmuyor.",
    box4Title: "Ana dilinizdeki beslemeyi görüntüleyin",
    box4Desc:
      "AI Güçlü Asistanımız, uygulamaya göz atarken beslemenizi uygun şekilde tercüme eder.",
  },
  section4: {
    forBusiness: "iş için",
    title: "İşletmenizi pazarlamak için sosyal medyayı kullanıyor musunuz?",
    desc: "Yeni yükseklikleri ölçeklendirin ve farklı coğrafyalarda müşterilere ulaşın. Dilin müşteri yaşam döngüsünüzü etkilemesine izin vermeyin. AI araçlarımızla reklamlarınızın TO'su'nu artırın ve daha geniş bir pazara ulaşın.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "Medya kiti",
    career: "Kariyer",
    disclaimer: "Feragatname",
    privacyPolicy: "Gizlilik Politikası",
    getConnected: "Bağlanmak",
    blog: "Blog",
    newsletter: "Bülten",
  },
  features: {
    TRAVEL: "SEYAHAT",
    DATING: "Flört",
    MARKETING: "PAZARLAMA",
    ENTERTAINMENT: "EĞLENCE",
    AID: "YARDIM",
    INTERCUTURAL: "Ortaklık",
    list1: {
      desc1:
        "Küresel bir maceraya başlayın - dünyanın her kültürünü ve köşesini keşfedin, keşfedin ve deneyimleyin.",
      desc2: "Seyahat Global",
    },
    list2: {
      desc1:
        "Kalbinizin eşleşmesini dünyanın dört bir yanındaki bul - bağlantı, sev ve sınırsız bir dünyada sınırları paylaşın.",
      desc2: "Küresel Arkadaşlık",
    },
    list3: {
      desc1:
        "Markanızı küresel ölçekte yükseltin - dünyanın dört bir yanındaki izleyicilere etkileşim kurun, etkileyin ve ilham verin.",
      desc2: "Küresel Pazarlama",
    },
    list4: {
      desc1:
        "Küresel eğlenceye dalın - Sınırsız bir eğlence, kahkaha ve sevinç dünyasını deneyimleyin.",
      desc2: "Küresel Eğlence",
    },
    list5: {
      desc1:
        "Küresel dayanışmada ellere katılın - daha iyi bir dünya için yaşamları birlikte güçlendirin, destekleyin ve dönüştürün.",
      desc2: "Küresel yardım",
    },
    list6: {
      desc1:
        "Koruyucu Anlama ve Bağlantı - Kültürleri ve toplulukları küresel olarak birleştiren zengin, çeşitli diyaloglara katılın.",
      desc2: "Küresel bağlantı",
    },
    title: "Küresel gitmek için inşa edin.",
  },
};

export default tr;
