import logo from "./logo.svg";
import "./styles/App.css";
import Layout from "./components/layout/Layout";
import Hero from "./components/hero";
import { useEffect, useState } from "react";
import zh_cn from "./language/zh_cn";
import en from "./language/en";
import es from "./language/es";
import pt from "./language/pt";
import zh_tw from "./language/zh_tw";
import ja from "./language/ja";
import ko from "./language/ko";
import vi from "./language/vi";
import hi from "./language/hi";
import tr from "./language/tr";
import Carousel from "./components/carousel";
import Slogans from "./components/slogans";
import Community from "./components/community";
import Features from "./components/features";
import Section4 from "./components/section4";
import Contacts from "./components/contacts";
import StayTunedModal from "./components/modal/StayTunedModal";
function App() {
  const [lang, setLang] = useState("en");
  const [langObj, setLangObj] = useState(lang);
  const [stayTunedModalOpen, setStayTunedModalOpen] = useState(false);

  const langList = {
    en,
    zh_cn,
    es,
    pt,
    zh_tw,
    ja,
    ko,
    vi,
    hi,
    tr,
  };

  useEffect(() => {
    const localLang = localStorage.getItem("lang");
    if (localLang) {
      setLangObj(langList[localLang]);
      setLang(localLang);
    } else {
      localStorage.setItem("lang", "en");
      setLangObj(langList[lang]);
    }
  }, [lang]);

  return (
    <Layout setLang={setLang} lang={lang} langObj={langObj}>
      <StayTunedModal
        langObj={langObj}
        open={stayTunedModalOpen}
        setStayTunedModalOpen={setStayTunedModalOpen}
      />
      <Hero langObj={langObj} />
      <div style={{ position: "relative" }}>
        <img
          src="/bg/gradient-bg-1.png"
          alt="gradient-bg-1"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "740px",
            pointerEvents: "none",
          }}
        />
      </div>
      <Carousel langObj={langObj} />
      <Slogans langObj={langObj} />
      <Community langObj={langObj} />
      <Section4 langObj={langObj} />
      <Features langObj={langObj} />
      <Contacts
        langObj={langObj}
        setStayTunedModalOpen={setStayTunedModalOpen}
      />
    </Layout>
  );
}

export default App;
