const hi = {
  common: {
    stayTuned: "रहो बने रहो",
  },
  menu: {
    about: "के बारे में",
    community: "समुदाय",
    contact: "संपर्क",
  },
  hero: {
    title1: "एआई के साथ नेक्स्टजेन सोशल मीडिया",
    title2: "अपनी भाषा में पूरी दुनिया",
    desc: "हमारे ऐप के साथ, भाषा आपके और पूरी दुनिया के बीच एक बाधा नहीं होगी; आप किसी से भी लिख सकते हैं और बात कर सकते हैं, जिससे खुद को कहीं भी लोगों द्वारा देखा और समझा जा सकता है। सभी जानकारी आपकी मातृभाषा में होगी; यहां कोई कोकून नहीं है, आपको एक वास्तविक वैश्विक दृष्टिकोण के साथ पेश करता है।",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "एक इच्छा के साथ शुरू करें",
    slide1Title:
      "क्या होगा अगर हर कोई भाषा की बाधाओं के बिना सभी से बात कर सकता है",
    slide1Desc:
      "यदि दुनिया भर के लोग प्रत्यक्ष, घर्षण रहित संवाद में संलग्न हो सकते हैं, तो शायद कम संघर्ष और थोड़ा अधिक खुशी होगी।",
    slide2Tag: "एआई द्वारा संचालित",
    slide2Title: "पहले दुनिया भर में ए-असिस्टेड आईएम और सोशल मीडिया",
    slide2Desc:
      "संवेदनहीन वॉयस मैसेज ट्रांसलेटर के साथ सांस्कृतिक और भाषा की बाधाओं को पूरा करें। हमारे बड़े भाषा मॉडल पर, रियलटाइम में अल्ट्रा यथार्थवादी परिणाम प्रदान करते हैं। आप वास्तव में बात कर सकते हैं और पूरी दुनिया को सुन सकते हैं।",
    slide3Tag: "एक मिशन के साथ मार्च करना",
    slide3Title:
      "वैश्वीकृत सोशल मीडिया को वैश्विक मानव एकता की भावना को बढ़ावा देना चाहिए",
    slide3Desc:
      "कोई फर्क नहीं पड़ता कि लोग कितनी दूर, अपने पड़ोसी की तरह आपसे संवाद कर सकते हैं और बात कर सकते हैं, और वे आपकी कहानियों को समझ सकते हैं। हर कोई अपनी मातृभाषा बोल रहा है, और दुनिया वास्तव में एक समुदाय की तरह महसूस करती है।",
  },
  slogan: {
    slogan1Title: "अनवधि",
    slogan1Desc: "पूरी दुनिया आपकी मातृभाषा में बोलती है",
    slogan2Title: "ताजा",
    slogan2Desc: "कई अन्य भाषाओं में अपने बोलने वाले वीडियो बनाएं",
    slogan3Title: "सरल",
    slogan3Desc:
      "अपने AI सहायक का निर्माण आपको स्वचालित रूप से उत्तर देने में मदद करता है",
  },
  community: {
    join: "जोड़ना",
    the: "",
    community: "समुदाय",
    box1Title: "आपका एआई अवतार",
    box1Desc:
      "तेजी से सोशल मीडिया की दुनिया के साथ नहीं रख सकते? एक WHIZ की तरह DMS का जवाब दें और अपने इनबॉक्स को 24/7 साफ रखें।",
    box2Title: "दुनिया को कनेक्ट करें",
    box2Desc:
      "अब आप भाषा की परवाह किए बिना दुनिया के आधे रास्ते के साथ दोस्त बना सकते हैं। सभी पाठ संदेश और सामग्री का अनुवाद वास्तविक समय में किया जाता है।",
    box3Title: "अपने सांस्कृतिक क्षितिज का विस्तार करें",
    box3Desc:
      "पूरी दुनिया को आपकी मातृभाषा में प्रस्तुत किया गया है, जिससे दुनिया भर से जानकारी, ज्ञान और मनोरंजन सामग्री का उपयोग करना आसान हो जाता है। भाषा अब आपकी जानकारी का कोकून नहीं बनाती है।",
    box4Title: "अपनी मूल जीभ में फ़ीड देखें",
    box4Desc:
      "हमारे एआई संचालित सहायक ने ऐप ब्राउज़ करते समय आसानी से आपके फ़ीड का अनुवाद किया।",
  },
  section4: {
    forBusiness: "व्यापार के लिए",
    title:
      "क्या आप अपने बिज़नेस की मार्केटिंग के लिए सोशल मीडिया का उपयोग करते हैं?",
    desc: "नई ऊंचाइयों को स्केल करें और अलग -अलग भूगोल में ग्राहकों तक पहुंचें। भाषा आपके ग्राहक जीवनचक्र को प्रभावित न करें। अपने विज्ञापनों के CTR बढ़ाएं और हमारे AI टूल के साथ एक व्यापक बाजार तक पहुंचें।",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "मीडिया किट",
    career: "आजीविका",
    disclaimer: "अस्वीकरण",
    privacyPolicy: "गोपनीयता नीति",
    getConnected: "जुड़ा हो",
    blog: "ब्लॉग",
    newsletter: "समाचार पत्रिका",
  },
  features: {
    TRAVEL: "यात्रा",
    DATING: "डेटिंग",
    MARKETING: "विपणन",
    ENTERTAINMENT: "मनोरंजन",
    AID: "सहायता",
    INTERCUTURAL: "अंतर -संबंधी",
    list1: {
      desc1:
        "एक वैश्विक साहसिक कार्य पर - दुनिया की हर संस्कृति और कोने की खोज, अन्वेषण और अनुभव करें।",
      desc2: "यात्रा वैश्विक",
    },
    list2: {
      desc1:
        "दुनिया भर में अपने दिल के मैच का पता लगाएं - बिना किसी सीमा के दुनिया में सीमाओं पर कनेक्ट, लव, और शेयर करें।",
      desc2: "ग्लोबल डेटिंग",
    },
    list3: {
      desc1:
        "अपने ब्रांड को एक वैश्विक स्तर पर ऊंचा करें - दुनिया भर में दर्शकों को संलग्न, प्रभाव और प्रेरित करें।",
      desc2: "वैश्विक विपणन",
    },
    list4: {
      desc1:
        "वैश्विक मनोरंजन में गोता लगाएँ - सीमाओं के बिना मज़े, हँसी और खुशी की दुनिया का अनुभव करें।",
      desc2: "वैश्विक मनोरंजन",
    },
    list5: {
      desc1:
        "वैश्विक एकजुटता में हाथ मिलाएं - एक बेहतर दुनिया के लिए एक साथ जीवन को सशक्त, समर्थन और रूपांतरित करें।",
      desc2: "वैश्विक सहायता",
    },
    list6: {
      desc1:
        "फोस्टर अंडरस्टैंडिंग और कनेक्शन - अमीर, विविध संवादों में संलग्न हैं जो विश्व स्तर पर संस्कृतियों और समुदायों को एकजुट करते हैं।",
      desc2: "वैश्विक संबंध",
    },
    title: "वैश्विक जाने के लिए निर्माण करें।",
  },
};
export default hi;
