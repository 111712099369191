const es = {
  common: {
    stayTuned: "MANTÉNGASE SINTONIZADO",
  },
  menu: {
    about: "Acerca de",
    community: "Comunidad",
    contact: "Contacto",
  },
  hero: {
    title1: "Nextgen Social Media con AI",
    title2: "En todo el mundo en tu idioma",
    desc: "Con nuestra aplicación, el lenguaje no sería una barrera entre usted y el mundo entero; Puedes escribir y hablar con cualquiera, haciéndote visto y entendido por las personas en cualquier lugar. Toda la información estaría en tu lengua materna; No hay capullo aquí, que te presenta una verdadera visión global.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "Empezar con un deseo",
    slide1Title:
      "¿Qué pasaría si todos pudieran hablar con todos sin barreras del idioma?",
    slide1Desc:
      "Si personas de todo el mundo pudieran participar en un diálogo directo y sin fricción, tal vez habría menos conflictos y un poco más de felicidad.",
    slide2Tag: "Impulsado por AI",
    slide2Title:
      "Primero en todo el mundo, IM y redes sociales de todo el mundo",
    slide2Desc:
      "Traverse las barreras culturales y del idioma con el traductor de mensajes de voz sin sentido. Construido en nuestro modelo de lenguaje grande, ofreciendo resultados ultra realistas en tiempo real. Realmente puedes hablar y escuchar todo el mundo.",
    slide3Tag: "Marchando con una misión",
    slide3Title:
      "Las redes sociales globalizadas deberían fomentar un sentido de la unidad humana global",
    slide3Desc:
      "No importa cuán lejos, las personas puedan comunicarse y hablar con usted como su vecino, y pueden entender sus historias. Todos hablan su lengua materna, y el mundo realmente se siente como una comunidad.",
  },
  slogan: {
    slogan1Title: "Sin fronteras",
    slogan1Desc: "El mundo entero habla en tu lengua materna",
    slogan2Title: "Vivamente",
    slogan2Desc: "Crea tus videos de oratoria en varios otros idiomas",
    slogan3Title: "Fácil",
    slogan3Desc:
      "construir su asistente de IA ayuda a responder automáticamente",
  },
  community: {
    join: "Unirse",
    the: "EL",
    community: "comunidad",
    box1Title: "Tu avatar de ai",
    box1Desc:
      "No puedes seguir el ritmo de las redes sociales? Mantén tu bandeja de entrada limpia 24/7.",
    box2Title: "Conectar el mundo",
    box2Desc:
      "Ahora puedes hacerte amigo de alguien a mitad de camino del mundo independientemente del idioma. Todos los mensajes de texto y contenido se traducen en tiempo real.",
    box3Title: "Amplíe sus horizontes culturales",
    box3Desc:
      "El mundo entero se presenta en su lengua materna, lo que facilita el acceso a la información, el conocimiento y el contenido de entretenimiento de todo el mundo. El lenguaje ya no forma su capullo de información.",
    box4Title: "Ver alimentación en su lengua materna",
    box4Desc:
      "Nuestro asistente de IA Powered traduce convenientemente su alimentación mientras navega por la aplicación.",
  },
  section4: {
    forBusiness: "para negocios",
    title: "¿Usas las redes sociales para promocionar tu negocio?",
    desc: "Alcanza nuevas alturas, conecta con clientes globalmente y optimiza el rendimiento de tus anuncios con nuestras herramientas de IA. Evita que el lenguaje sea una barrera en el ciclo de vida del cliente.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "Kit de medios",
    career: "Carrera",
    disclaimer: "Descargo",
    privacyPolicy: "política de privacidad",
    getConnected: "Conectarse",
    blog: "Blog",
    newsletter: "Boletin informativo",
  },
  features: {
    TRAVEL: "VIAJAR",
    DATING: "TENER UNA CITA",
    MARKETING: "MARKETING",
    ENTERTAINMENT: "ENTRETENIMIENTO",
    AID: "AYUDA",
    INTERCUTURAL: "Intercutural",
    list1: {
      desc1:
        "Embárcase en una aventura global: descubra, explore y experimente todas las culturas y rincones del mundo.",
      desc2: "Viajes Globales",
    },
    list2: {
      desc1:
        "Encuentra el partido de tu corazón en todo el mundo: conectar, amar y compartir a través de las fronteras en un mundo sin límites.",
      desc2: "Citas globales",
    },
    list3: {
      desc1:
        "Eleve su marca a escala global: participar, influir e inspirar al público en todo el mundo.",
      desc2: "Marketing Global",
    },
    list4: {
      desc1:
        "Sumérgete en el entretenimiento global: experimenta un mundo de diversión, risa y alegría sin límites.",
      desc2: "Entretenimiento Global",
    },
    list5: {
      desc1:
        "Une las manos en la solidaridad global: empoderar, apoyar y transformar vidas juntas para un mundo mejor.",
      desc2: "Ayuda Global",
    },
    list6: {
      desc1:
        "Fomentar la comprensión y la conexión: participar en diálogos ricos y diversos que unan culturas y comunidades a nivel mundial.",
      desc2: "Conexión Global",
    },
    title: "Construir para globalizarse.",
  },
};

export default es;
