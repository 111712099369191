import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as Crsl } from "react-responsive-carousel";
import { useIsPC } from "../../hooks/useMediaQuery";

const Carousel = ({ langObj }) => {
  const isPC = useIsPC();
  const [selectedItem, setSelectedItem] = useState(0);

  const slideList = [
    {
      tag: langObj?.carousel?.slide1Tag,
      title: langObj?.carousel?.slide1Title,
      desc: langObj?.carousel?.slide1Desc,
    },
    {
      tag: langObj?.carousel?.slide2Tag,
      title: langObj?.carousel?.slide2Title,
      desc: langObj?.carousel?.slide2Desc,
    },
    {
      tag: langObj?.carousel?.slide3Tag,
      title: langObj?.carousel?.slide3Title,
      desc: langObj?.carousel?.slide3Desc,
    },
  ];

  const handleChangeItem = (number) => {
    setSelectedItem(number);
  };

  return (
    <>
      <div className="carousel-container">
        <Crsl
          showArrows={false}
          infiniteLoop={true}
          width={isPC ? "85vw" : "90vw"}
          emulateTouch={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={selectedItem}
          onChange={(e) => {
            handleChangeItem(e);
          }}
        >
          {slideList.map((item, index) => {
            return (
              <div
                className="carousel-block"
                key={index}
                style={{ height: "100%" }}
              >
                <div style={{ height: "100%" }}>
                  <img
                    src={`/img/carousel-img-${index + 1}.png`}
                    alt="carousel img"
                    className="carousel-image"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      borderTopLeftRadius: "1rem",
                      borderBottomLeftRadius: "1rem",
                    }}
                  />
                </div>
                <div className="carousel-detail">
                  <div className="carousel-tag">{item.tag}</div>
                  <div className="carousel-title">{item.title}</div>
                  <div className="carousel-description">{item.desc}</div>
                </div>
              </div>
            );
          })}
        </Crsl>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
          marginBottom: "5rem",
        }}
      >
        <div
          className={selectedItem === 0 ? "dot" : "dot-line"}
          onClick={() => handleChangeItem(0)}
        ></div>
        <div
          className={selectedItem === 1 ? "dot" : "dot-line"}
          onClick={() => handleChangeItem(1)}
        ></div>
        <div
          className={selectedItem === 2 ? "dot" : "dot-line"}
          onClick={() => handleChangeItem(2)}
        ></div>
        {/* <div
          className={selectedItem === 3 ? "dot" : "dot-line"}
          onClick={() => handleChangeItem(3)}
        ></div> */}
      </div>
    </>
  );
};

export default Carousel;
