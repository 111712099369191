const ko = {
  common: {
    stayTuned: "기다려 주세요",
  },
  menu: {
    about: "에 대한",
    community: "지역 사회",
    contact: "연락하다",
  },
  hero: {
    title1: "AI와 함께 NextGen 소셜 미디어",
    title2: "당신의 언어로 전 세계",
    desc: "우리의 앱을 통해 언어는 당신과 전 세계 사이의 장벽이 아닙니다. 당신은 누구에게나 글을 쓰고 이야기 할 수 있으며, 어디서나 사람들이보고 이해하게합니다. 모든 정보는 모국어에있을 것입니다. 여기에 누에 고치가 없어 진정한 전 세계적으로 볼 수 있습니다.",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "소원으로 시작하십시오",
    slide1Title: "모든 사람이 언어 장벽없이 모든 사람과 대화 할 수 있다면",
    slide1Desc:
      "전 세계의 사람들이 직접적이고 마찰이없는 대화에 참여할 수 있다면 갈등이 적고 행복이 조금 더있을 것입니다.",
    slide2Tag: "AI에 의해 구동됩니다",
    slide2Title: "최초의 AI-AID IM 및 소셜 미디어",
    slide2Desc:
      "무의미한 음성 메시지 번역기를 사용한 문화 및 언어 장벽을 통과합니다. 큰 언어 모델에 구축되어 실시간으로 매우 현실적인 결과를 제공합니다. 당신은 정말로 전 세계의 이야기하고들을 수 있습니다.",
    slide3Tag: "임무와 함께 행진",
    slide3Title: "세계화 된 소셜 미디어",
    slide3Desc:
      "아무리 멀리 있더라도 사람들은 이웃처럼 의사 소통하고 이야기 할 수 있으며, 당신의 이야기를 이해할 수 있습니다. 모두가 모국어를 말하고 있으며 세상은 진정으로 공동체처럼 느껴집니다.",
  },
  slogan: {
    slogan1Title: "무제한",
    slogan1Desc: "전 세계는 모국어로 말합니다",
    slogan2Title: "생생하게",
    slogan2Desc: "여러 언어로 말하는 비디오를 만듭니다",
    slogan3Title: "힘들지 않습니다",
    slogan3Desc: "AI 어시스턴트를 구축하면 자동으로 답장을받을 수 있습니다",
  },
  community: {
    join: "가입하다",
    the: "그만큼",
    community: "지역 사회",
    box1Title: "당신의 ai 아바타",
    box1Desc:
      "빠른 소셜 미디어 세계를 따라갈 수 없습니까? Whiz와 같은 DM에 응답하고받은 편지함을 24/7 청소하십시오.",
    box2Title: "세상을 연결하십시오",
    box2Desc:
      "이제 언어에 관계없이 세상의 중간에있는 누군가와 친구를 사귀 수 있습니다. 모든 문자 메시지와 내용은 실시간으로 번역됩니다.",
    box3Title: "문화적 지평을 확장하십시오",
    box3Desc:
      "전 세계는 모국어로 제공되므로 전 세계의 정보, 지식 및 엔터테인먼트 컨텐츠에 쉽게 액세스 할 수 있습니다. 언어는 더 이상 당신의 정보 고치를 형성하지 않습니다.",
    box4Title: "모국어의 피드를 봅니다",
    box4Desc:
      "앱을 탐색하는 동안 AI 구동 조수가 피드를 모두 편리하게 번역합니다.",
  },
  section4: {
    forBusiness: "비즈니스를위한",
    title: "소셜 미디어를 사용하여 비즈니스를 마케팅합니까?",
    desc: "새로운 차원을 확장하고 다른 지역에서 고객에게 도달하십시오. 언어가 고객 수명주기에 영향을 미치지 않도록하십시오. 광고의 CTR을 늘리고 AI 도구를 사용하여 더 넓은 시장에 도달하십시오.",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "미디어 키트",
    career: "직업",
    disclaimer: "부인 성명",
    privacyPolicy: "개인 정보 정책",
    getConnected: "연결 고리를 갖다",
    blog: "블로그",
    newsletter: "뉴스 레터",
  },
  features: {
    TRAVEL: "여행하다",
    DATING: "데이트",
    MARKETING: "마케팅",
    ENTERTAINMENT: "오락",
    AID: "지원",
    INTERCUTURAL: "상호 작용",
    list1: {
      desc1:
        "세계의 모든 문화와 모퉁이를 발견하고 탐구하고 경험하는 세계적인 모험을 시작하십시오.",
      desc2: "전 세계 여행",
    },
    list2: {
      desc1:
        "전 세계에서 마음의 경기를 찾으십시오 - 제한없이 세상에서 국경을 넘어 연결, 사랑 및 공유하십시오.",
      desc2: "글로벌 데이트",
    },
    list3: {
      desc1:
        "전 세계 청중을 참여, 영향 및 영감을주는 글로벌 규모로 브랜드를 높이십시오.",
      desc2: "글로벌 마케팅",
    },
    list4: {
      desc1:
        "글로벌 엔터테인먼트에 뛰어 들어 경계없이 재미, 웃음, 기쁨의 세계를 경험하십시오.",
      desc2: "글로벌 엔터테인먼트",
    },
    list5: {
      desc1:
        "글로벌 연대에 손을 대고 더 나은 세상을 위해 힘을 얻고 지원하며 삶을 변화 시키십시오.",
      desc2: "글로벌 원조",
    },
    list6: {
      desc1:
        "이해와 연결을 육성 - 전 세계 문화와 지역 사회를 통합하는 풍부하고 다양한 대화에 참여하십시오.",
      desc2: "글로벌 연결",
    },
    title: "글로벌로 가기 위해 구축하십시오.",
  },
};

export default ko;
