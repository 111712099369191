const zh_tw = {
  common: {
    stayTuned: "敬請期待",
  },
  menu: {
    about: "關於",
    community: "社區",
    contact: "聯繫我們",
  },
  hero: {
    title1: "新一代AI助力社交媒體",
    title2: "讓全世界都說你的語言",
    desc: "在VeryReal App,您與全世界之間不再有語言障礙。 您可以與任何人寫作和交談,被世界各地的人們看到和理解。 所有的資訊以您的母語呈現;這裡沒有資訊繭房,向您展現真正的全球視野。 ",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "從一個願望開始",
    slide1Title: "如果每個人都可以在沒有語言障礙的情況下與所有人交談",
    slide1Desc:
      "如果來自世界各地的人們可以進行直接對話,那麼也許會有更少的衝突和更多的幸福。 ",
    slide2Tag: "由人工智慧驅動",
    slide2Title: "首個全球化的AI輔助即時通訊軟體和社交媒體",
    slide2Desc:
      "通過沉浸式地語音消息翻譯,VeryReal説明你穿越文化和語言障礙。 它在我們的大語言模型上構建,提供貼切的全面時時翻譯,讓您可以真正談論和傾聽整個世界。 ",
    slide3Tag: "帶著使命再前進",
    slide3Title: "用全球化的社交媒體滋養一種全球公民的文化",
    slide3Desc:
      "無論多遠,人們都可以像鄰居一樣交談,他們可以理解與同情遠在他鄉的故事。 每個人都在說自己的母語,真正地感覺到全世界就像一個社區。 ",
  },
  slogan: {
    slogan1Title: "沒有語言邊界",
    slogan1Desc: "全世界用你的母語說話",
    slogan2Title: "傳遞無邊活力",
    slogan2Desc: "用其他多種語言創建您的視頻表達",
    slogan3Title: "自建AI助理",
    slogan3Desc: "建立您的AI助手可幫助您自動回復",
  },
  community: {
    join: "加入",
    the: "這個",
    community: "全球社區",
    box1Title: "打造你的AI分身",
    box1Desc:
      "無法跟上快速的社交媒體世界？通過AI分身自動回應DMS,時刻保持收件箱24/7完美智能回復。 ",
    box2Title: "連接世界各地",
    box2Desc:
      "現在,無論任何語言,您都可以與世界另一端的人交朋友。 所有對話與語音均以即時翻譯。 ",
    box3Title: "擴展文化視野",
    box3Desc:
      "全世界資訊都以您的母語呈現,輕鬆訪問來自全球的信息、知識和娛樂。 語言將不再構成資訊繭房。 ",
    box4Title: "用母語時時反饋",
    box4Desc:
      "當您瀏覽我們的應用程式時,我們的AI助理也會隨時將您的反饋,隨時傳遞為他人可讀的語言。 ",
  },
  section4: {
    forBusiness: "還有廣闊的商業用途",
    title: "您是否使用社交媒體來推銷您的業務？",
    desc: "通過我們您可以將業務擴展到新高度,在世界各地輕鬆吸引客戶。 語言不再會影響您的獲客與維護。 顯著增加廣告的CTR,我們的AI工具助力您到達更廣泛的市場。 ",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "媒體工具包",
    career: "職業發展",
    disclaimer: "免責聲明",
    privacyPolicy: "隱私政策",
    getConnected: "保持聯絡",
    blog: "博客",
    newsletter: "郵件訂閱",
  },
  features: {
    TRAVEL: "旅行",
    DATING: "約會",
    MARKETING: "行銷",
    ENTERTAINMENT: "娛樂",
    AID: "援助",
    INTERCUTURAL: "跨文化",
    list1: {
      desc1: "踏上全球冒險 - 發現、探索和體驗世界上每個文化和角落。 ",
      desc2: "旅行全球",
    },
    list2: {
      desc1: "在全球範圍內找到您的心跳匹配 - 在世界上連接愛和感情。 ",
      desc2: "全球約會",
    },
    list3: {
      desc1: "在全球範圍內提升您的品牌 - 參與、影響和吸引世界各地的顧客。 ",
      desc2: "全球行銷",
    },
    list4: {
      desc1: "更多豐富環球娛樂 - 理解帶來樂趣,一個歡樂的全世界向你打開。 ",
      desc2: "全球娛樂",
    },
    list5: {
      desc1:
        "更多理解更多團結 - 聽見、看見並理解遠方的苦難,共同援助更美好的世界。 ",
      desc2: "全球援助",
    },
    list6: {
      desc1: "促進跨文化理解和聯繫 - 説明多文化的家庭、社區開展輕鬆的對話。 ",
      desc2: "全球連接",
    },
    title: "為走向全球而生。 ",
  },
};

export default zh_tw;
