const ja = {
  common: {
    stayTuned: "お楽しみに",
  },
  menu: {
    about: "について",
    community: "コミュニティ",
    contact: "接触",
  },
  hero: {
    title1: "AIを使用したNextGenソーシャルメディア",
    title2: "あなたの言語の全世界",
    desc: "私たちのアプリでは、言語はあなたと全世界の間の障壁ではありません。あなたは誰でも書いて話すことができ、どこでも人々に見られ、理解させることができます。すべての情報はあなたの母国語にあります。ここにはcocoはありませんが、本当のグローバルな見解を提示します。",
    android: "Android",
    ios: "iOS",
  },
  carousel: {
    slide1Tag: "願いから始めてください",
    slide1Title:
      "誰もが言語の障壁なしですべての人と話すことができたらどうしますか",
    slide1Desc:
      "世界中の人々が直接的な摩擦のない対話に従事することができれば、おそらく紛争が少なく、もう少し幸せがあるでしょう。",
    slide2Tag: "AIを搭載",
    slide2Title: "世界的に世界的にアシストされたIMとソーシャルメディア",
    slide2Desc:
      "無意味な音声メッセージ翻訳者による文化的および言語の障壁を横断します。私たちの大規模な言語モデルに基づいて、超現実的な結果をリアルタイムで提供します。あなたは本当に全世界の話をして聴くことができます。",
    slide3Tag: "ミッションで行進",
    slide3Title:
      "グローバル化されたソーシャルメディアは、グローバルな人間の団結感を育むべきです",
    slide3Desc:
      "どんなに遠く離れていても、人々はあなたの隣人のようにコミュニケーションを取り、話すことができ、彼らはあなたの物語を理解できます。誰もが母国語を話すので、世界は本当にコミュニティのように感じられます。",
  },
  slogan: {
    slogan1Title: "ボーダーレス",
    slogan1Desc: "全世界はあなたの母国語で話します",
    slogan2Title: "鮮やかに",
    slogan2Desc: "他の複数の言語でスピーキングビデオを作成します",
    slogan3Title: "楽な",
    slogan3Desc: "AIアシスタントを構築するのは、自動的に返信するのに役立ちます",
  },
  community: {
    join: "参加する",
    the: "",
    community: "コミュニティ",
    box1Title: "あなたのaiアバター",
    box1Desc:
      "ラピッドソーシャルメディアの世界に追いつくことができませんか？ whizのようなDMSに応答し、受信トレイを24時間年中無休で清潔に保ちます。",
    box2Title: "世界をつなぎます",
    box2Desc:
      "言語に関係なく、世界の途中で誰かと友達を作ることができます。すべてのテキストメッセージとコンテンツはリアルタイムで翻訳されます。",
    box3Title: "あなたの文化的視野を広げます",
    box3Desc:
      "全世界は母国語で提示されており、世界中の情報、知識、エンターテイメントのコンテンツに簡単にアクセスできます。言語はもはやあなたの情報のcocoを形成しません。",
    box4Title: "母国語でフィードを表示します",
    box4Desc:
      "AI搭載のアシスタントは、アプリを閲覧している間、すべてのフィードを便利に翻訳します。",
  },
  section4: {
    forBusiness: "ビジネスのための",
    title: "ソーシャルメディアを使用してビジネスを販売していますか？",
    desc: "新しい高さを拡大し、さまざまな地域で顧客にリーチします。言語が顧客のライフサイクルに影響を与えないでください。広告のCTRを増やし、AIツールを使用してより広い市場に到達します。",
  },
  contacts: {
    copyright: "VERY REAL AI INC.",
    email: "contact@veryreal.ai",
    veryRealAI: "VeryRealAI",
    mediaKit: "メディアキット",
    career: "キャリア",
    disclaimer: "免責事項",
    privacyPolicy: "プライバシーポリシー",
    getConnected: "接続します",
    blog: "ブログ",
    newsletter: "ニュースレター",
  },
  features: {
    TRAVEL: "旅行",
    DATING: "デート",
    MARKETING: "マーケティング",
    ENTERTAINMENT: "エンターテインメント",
    AID: "援助",
    INTERCUTURAL: "間隔",
    list1: {
      desc1:
        "世界的な冒険に乗り出す - 世界のすべての文化と角を発見、探求、体験してください。",
      desc2: "旅行グローバル",
    },
    list2: {
      desc1:
        "世界中であなたの心の試合を見つけてください - 制限なしに世界の国境を越えてつながり、愛、共有してください。",
      desc2: "グローバルデート",
    },
    list3: {
      desc1:
        "世界中の視聴者を魅了し、影響を与え、インスピレーションを与え、世界規模でブランドを高めてください。",
      desc2: "グローバルマーケティング",
    },
    list4: {
      desc1:
        "グローバルなエンターテイメントに飛び込みます - 境界のない楽しみ、笑い、喜びの世界を体験してください。",
      desc2: "グローバルエンターテイメント",
    },
    list5: {
      desc1:
        "グローバルな連帯に手を加えてください - より良い世界のために、生活をエンパワーし、支援し、人生を変えてください。",
      desc2: "グローバルな援助",
    },
    list6: {
      desc1:
        "理解とつながりを促進する - 世界的に文化とコミュニティを団結させる豊かで多様な対話に従事します。",
      desc2: "グローバル接続",
    },
    title: "グローバルになるためにビルド。",
  },
};

export default ja;
