import React from "react";
import { useIsPC } from "../../hooks/useMediaQuery";

const Section4 = ({ langObj }) => {
  const isPC = useIsPC();

  return (
    <div className="section-4-container">
      <div className="section-4-card" style={{ width: isPC ? "85vw" : "90vw" }}>
        <div className="section-4-card-left">
          <img
            src="/bg/section-4-card-bg.png"
            alt="card bg"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              right: 0,
              left: 0,
            }}
          />
          <div className="section-4-card-left-detail">
            <img
              src="/img/section-4-card-logo.png"
              alt="header-logo"
              style={{
                width: "316px",
                height: "75px",
              }}
            />
            {langObj?.section4?.forBusiness}
          </div>
        </div>
        <div className="section-4-card-right">
          <div className="section-4-title">{langObj?.section4?.title}</div>
          <div className="section-4-description">{langObj?.section4?.desc}</div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
