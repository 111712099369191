import React from "react";
import { motion } from "framer-motion";

export const CommunityCard = ({ langObj, line = 1 }) => {
  const title =
    line === 1
      ? langObj?.community?.box1Title
      : line === 2
      ? langObj?.community?.box2Title
      : line === 3
      ? langObj?.community?.box3Title
      : line === 4
      ? langObj?.community?.box4Title
      : langObj?.community?.box1Title;

  const desc =
    line === 1
      ? langObj?.community?.box1Desc
      : line === 2
      ? langObj?.community?.box2Desc
      : line === 3
      ? langObj?.community?.box3Desc
      : line === 4
      ? langObj?.community?.box4Desc
      : langObj?.community?.box1Desc;

  return (
    <motion.div
      className={`community-wrapper community-wrapper-${line}`}
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      duration={{ delay: 1 }}
    >
      <div style={{ width: "320px", padding: "1rem" }}>
        <div className="community-title">{title}</div>
        <div style={{ overflowWrap: "break-word" }}>{desc}</div>
      </div>
    </motion.div>
  );
};

const Community = ({ langObj }) => {
  return (
    <div
      style={{
        padding: "3rem 2rem 2rem 2rem",
        minHeight: "100vh",
        maxWidth: "1250px",
        margin: "auto",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        id="community"
        style={{ position: "absolute", top: "-8rem", left: "0rem" }}
      ></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <motion.div
          style={{
            color: "white",
            fontFamily: "Barlow Condensed",
            fontSize: "5rem",
            fontWeight: 600,
            fontStyle: "italic",
            marginRight: "1rem",
          }}
          initial={{ opacity: 0, y: 200 }}
          whileInView={{ opacity: 1, y: 0 }}
          duration={{ delay: 1 }}
        >
          {langObj?.community?.join}{" "}
          <span style={{ fontFamily: "Anonymous Pro", color: "#78FD96" }}>
            {langObj?.community?.the}
          </span>{" "}
          <br />
          {langObj?.community?.community}
        </motion.div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="community-section-2">
            <CommunityCard langObj={langObj} />
            <CommunityCard langObj={langObj} line={2} />
          </div>
          <div style={{ marginTop: "-3rem" }}>
            <CommunityCard langObj={langObj} line={3} />
            <CommunityCard langObj={langObj} line={4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
